/*
**
** Just some trail css styles included to add some gradient stuff to the images in the about page
** <-- Do not use it elsewhere without testing!! -->
*/

.about-image {
  border-radius: 999px;
  display: block;
  width: calc(100vw - 80px);
  max-width: 360px;
  height: auto;
  object-fit: cover;
}

@media (max-width: 444px){

  .about-image{  
    width: 9rem !important;
    height: 9rem !important;
  }
}

.aboutButtonsContainer{
  width : "100%";
  column-gap: 24px;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px){
    .aboutButtonsContainer{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
}

