@import url('https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap');

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
}

body {
  // background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: 'Calibri';
  font-weight: 400;
  line-height: 1.5em;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.6em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
  margin-top: 10px;
  margin-bottom: 10px;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 500;
}

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

p {
  margin: 0 0 10px;
}

body {
  // background-color: #eeeeee;
  color: #3c4858;
  font-size: 14px;
}

.full-body {
  min-height: calc(100vh - 104px);
}


blockquote p {
  font-style: italic;
}

a {
  color: #233750;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #3d97d3;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"]>input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media only screen and (max-width: 960px) {
  .footeMenu {
    text-align: center;

    a {
      display: inline-block;
      float: center;
    }
  }

  // Nav Bar
  .mainMenu {
    padding: 0px !important;
    text-align: center;
    display: block !important;

    button {
      padding-top: 10px;
    }
  }
}

@media only screen and (min-width: 960px) {
  .paddingOnLarge {
    padding: 1.5em
  }

  .navbar-container {
    position: fixed;
    width: 100%;
    z-index: 999;
  }

  .full-body {
    margin-top: 56px;
  }

}

.adminLogin {
  right: 0px;
  position: absolute;
}

@media only screen and (min-width: 768px){
  .adminLogin{
    position: relative;
    flex-basis: unset !important;
  }
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

@media (max-width: 991px) {

  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}

svg {
  touch-action: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: 400;
  line-height: 1;
  color: #777;
}

h4 small,
h5 small,
h6 small {
  font-size: 75%;
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

img {
  vertical-align: middle;
  border: 0;
}

.container-main {
  min-height: calc(100vh - 104px);
}

.questionAnswersVertical{
  strong{
    display:table-row;
  }
}

.largeText{
  cursor: pointer;
}

.dropdownTime{
      background: white;
    padding: 10px;
    margin-top: 20px;
    /* width: 100%; */
    /* max-width: 300px; */
    position: absolute;
    z-index: 2;
    border: 1px solid;
  .triangle{
    margin-top: -29px;
    color: black;
    font-size: 26px;
  }
}

.paddingleft--15{
      padding-left: 0px !important;
}

.questionAnswers{
  strong:not(:last-child)::after{
     content: ", ";
  }
}

.link{
  button{
    padding: 0px 5px 0px 0px;
    float: left;
  }
  .linktext{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 30px);
    float: right;
    margin-top: 3px;
  }
}

// to fix zoom buttons not appearing in google maps
.gmnoprint button {
  max-width: none; 
}

.gmnoprint div {
  max-width: none; 
}
